import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FaBars, FaTimes, FaGithub, FaLinkedin, FaRss } from 'react-icons/fa'
/* App imports */
import useEvent from '../../hooks/useEvent'
import Config from '../../../../config'
import Utils from '../../../utils'
import {container} from './header.module.less'
import {titleContainer} from './header.module.less'
import {title} from './header.module.less'
import {menuButton} from './header.module.less'
import {hiddenDescription} from './header.module.less'
import {visibleDescription} from './header.module.less'
import {list} from './header.module.less'
import {collapsedMenu} from './header.module.less'
import {expandedMenu} from './header.module.less'

const Header = () => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(false)
  const [isHeaderCollapsed, setHeaderCollapsed] = useState(false)

  function toggleFixedHeader() {
    if (!isHeaderCollapsed && window.scrollY > 100) {
      setHeaderCollapsed(true)
    } else if (isHeaderCollapsed && window.scrollY < 100) {
      setHeaderCollapsed(false)
    }
  }

  function toggleMenu() {
    setMenuCollapsed(!isMenuCollapsed)
  }

  useEvent('scroll', toggleFixedHeader)

  return (
    <div
      className={container}
      style={isHeaderCollapsed ? { backgroundImage: 'none' } : null}
    >
      <div className={titleContainer}>
        <div className={title}>
          <Link to={Utils.resolvePageUrl(Config.pages.home)}>
            <h4>{Config.siteTitle}</h4>
            <p
              className={
                isHeaderCollapsed
                  ? hiddenDescription
                  : visibleDescription
              }
            >
             <span>将棋ウォーズ実戦記、詰将棋、次の一手</span>
            </p>
          </Link>
        </div>
        <div className={menuButton}>
          {isMenuCollapsed ? (
            <FaBars size="30" onClick={toggleMenu} />
          ) : (
            <FaTimes size="30" onClick={toggleMenu} />
          )}
        </div>
      </div>
      <div
        className={[
          list,
          isMenuCollapsed ? collapsedMenu : expandedMenu,
        ].join(' ')}
      >
        <ul>
          <li>
            <Link to={Utils.resolvePageUrl(Config.pages.home)}>Home</Link>
          </li>
          <li>
            <Link to="/tag">Tags</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

//<Link to={Utils.resolvePageUrl(Config.pages.tag)}>Tags</Link>
//<Link to={Utils.resolvePageUrl(Config.pages.about)}>About</Link>

export default Header
