
module.exports = {
  pathPrefix: '/',
  siteUrl: 'https://shogi-diary.tokyo/',
  siteTitle: '駒と日常と私',
  siteDescription: '将棋好きサラリーマンの将棋ブログです。アマチュア三段（将棋ウォーズ四段）の筆者の将棋ウォーズの実戦記を中心に、実戦詰将棋、実戦次の一手なども紹介します。',
  author: '零',
  postsForArchivePage: 5,
  defaultLanguage: 'ja',
  pages: {
    home: '/',
    blog: 'blog',
    about: 'about',
    tag: 'tag',
    archive: 'archive'
  },
  social: {
    github: '',
    linkedin: '',
    rss: ''
  },
  tags: {
    shogi: {
      name: '将棋',
      description: '将棋関連の記事です。棋界の話題のニュースや、自身の将棋ネタなどを紹介します。'
    },
    shogiwars: {
      name: '将棋ウォーズ',
      description: '自分の将棋ウォーズの対局のうち、振り返って考察したり反省したりしたいものをピックアップしました。'
    },
    tsumeShogi: {
      name: '詰将棋',
      description: '自作詰め将棋、実践詰将棋を中心に紹介します。'
    },
    tsuginoitte: {
      name: '次の一手',
      description: '実践からの出題を中心に紹介します。'
    },
    useful: {
      name: 'お役立ち',
      description: '将棋に限らず、日常からビジネスまで幅広いお役立ち情報を紹介します。'
    },
    story: {
      name: '語り',
      description: '自分の想いや感想をつらつら語ります。'
    }
  }
}

/**disqusScript: 'https://luigi-colella.disqus.com/embed.js', */