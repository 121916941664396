/* Vendor imports */
import React from 'react'
/* App imports */
import {container} from './footer.module.less'

const Footer = () => (
  <div className={container}>
    <p>Made with Gatbsy.js, hosted on Netlify</p>
  </div>
)

export default Footer
